<template>
  <BCol
    md="12"
    lg="6"
    cols="12"
  >
    <BCard>
      <div class="mb-1 flex flex-row justify-between">
        <div class="text-black font-semibold text-xl">
          Arus Keuangan Kartu
        </div>
        <div class="w-[120px]">
          <FlatPickr
            v-model="period"
            :config="configDate"
            class="custom-select"
          />
        </div>
      </div>
      <BOverlay
        :show="loading"
        spinner-variant="primary"
        variant="light"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <ApexChart
          ref="chart"
          type="area"
          :options="options"
          :series="series"
        />
      </BOverlay>
    </BCard>
  </BCol>
</template>

<script>
import ApexChart from 'vue-apexcharts'
import { IDR } from '@/libs/currency'
import {
  DATE, DAY_MONTH_YEAR, END_DATE_OF_MONTH, START_DATE_OF_MONTH, YEAR_MONTH,
} from '@/libs/formatDate'
import { newAxiosIns } from '@/libs/axios'
import FlatPickr from 'vue-flatpickr-component'
import { Indonesian } from 'flatpickr/dist/l10n/id'
import MonthSelect from 'flatpickr/dist/plugins/monthSelect'
import '@/@core/scss/vue/libs/vue-flatpicker.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'
import { today } from '@/store/helpers'

export default {
  components: { ApexChart, FlatPickr },
  data() {
    return {
      period: today,
      loading: false,
      series: [],
      configDate: {
        mode: 'single',
        locale: Indonesian,
        altInput: true,
        altFormat: 'j/n/Y',
        dateFormat: 'Y-m-d',
        maxDate: today,
        disableMobile: true,
        plugins: [
          new MonthSelect({
            shorthand: true,
            dateFormat: 'Y-m-d',
            altFormat: 'M Y',
          }),
        ],
      },
      options: {
        chart: {
          type: 'area',
          stacked: false,
          height: 265,
          toolbar: { show: false },
          zoom: { enabled: false },
        },
        dataLabels: { enabled: false },
        stroke: {
          curve: 'smooth',
        },
        fill: {
          type: 'gradient',
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.75,
            opacityTo: 0.05,
            stops: [20, 100, 100, 100],
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            style: {
              colors: '#000000',
            },
            offsetX: 0,
            formatter: val => `${IDR(val)}`,
          },
          axisBorder: { show: false },
          axisTicks: { show: false },
        },
        xaxis: {
          type: 'datetime',
          tickAmount: 15,
          min: START_DATE_OF_MONTH,
          max: END_DATE_OF_MONTH,
          categories: [],
          labels: {
            formatter: value => DATE(value),
          },
        },
        grid: {
          yaxis: {
            lines: {
              offsetX: -30,
            },
          },
          padding: {
            left: 20,
          },
        },
        colors: ['#34A770', '#E31A1A'],
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
        noData: {
          text: 'Tidak ada data yang di tampilkan',
          align: 'center',
          verticalAlign: 'middle',
        },
        tooltip: {
          x: {
            show: true,
            formatter: value => DAY_MONTH_YEAR(value),
          },
          fixed: {
            enabled: false,
            position: 'topRight',
          },
          custom: ({ dataPointIndex, w }) => {
            let htmlRender = ''
            const arrayData = [...w.globals.series]
            arrayData.forEach((x, idx) => {
              if (w.globals.collapsedSeriesIndices.indexOf(idx) !== -1) {
                htmlRender += ''
              } else {
                htmlRender += `<li class="my-0 mt-1" style="color: ${['#34A770', '#E31A1A'][idx]};">
                <span class="text-black">${IDR(Math.abs(x[dataPointIndex]) || 0)}</span> 
                </li>`
              }
            })
            return `<div class="d-grid px-1 rounded align-items-center">
              ${htmlRender}
              <br/>
            </div>`
          },
        },
      },
    }
  },
  watch: {
    period: {
      handler() {
        this.getTransactionFlow()
      },
    },
  },
  mounted() {
    this.getTransactionFlow()
  },
  methods: {
    async getTransactionFlow() {
      this.loading = true
      const params = YEAR_MONTH(this.period)
      const url = `/komcards/api/v1/dashboard/card-flow-transaction?period=${params}`
      await newAxiosIns.get(url)
        .then(res => {
          const { data } = res.data
          this.series = [
            {
              name: 'Transaksi Masuk',
              data: data.map(item => item.credit_amount),
            },
            {
              name: 'Transaksi Keluar',
              data: data.map(item => -item.debit_amount),
            },
          ]
          this.loading = false
          this.options = {
            ...this.options,
            xaxis: {
              ...this.options.xaxis,
              categories: data.map(item => item.date),
            },
          }
        })
        .catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal load data arus keuangan kartu' })
        })
    },
  },
}
</script>
<style>
.flatpickr-monthSelect-month.selected{
  background-color: #F95031 !important;
}
</style>
